<template>
  <div class="cell-wrapper">
    <img :src="img" class="image" />
    <div class="right">
      <div class="title h2 single-line">{{ info.title }}</div>
      <div class="tags">
        <div class="h3 tag-item">{{ info.businessAddress }}</div>
        <div class="h3 tag-item" v-if="info.isEia=='1'">可办环评</div>
      </div>
      <div class="contacts">联系人：{{ info.contactName }}</div>
    </div>
    <div class="button" @click="handleClick">查看详情</div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data() {
    return {}
  },
  computed: {
    img() {
      if (this.info.itemPicUrl) {
        return this.info.itemPicUrl
      }
      if (this.info?.images.length > 0) {
        return this.info?.images[0]
      }
      return ''
    },
  },
  created() { },
  mounted() {

  },
  methods: {
    handleClick() {
      this.$emit('handleClick', this.info)
    },
  }
}
</script>

<style scoped lang="less">
.cell-wrapper {
  height: 120px;
  display: flex;
  align-items: stretch;
  padding: 30px;
  border-bottom: #dfdfdf 1px solid;
  .image {
    width: 200px;
    height: 100%;
    margin-right: 20px;
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .title {
      height: 30px;
    }
    .content {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .tags {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .tag-item {
        padding-right: 10px;
        margin-right: 10px;
        border-right: #dfdfdf 1px solid;
      }
    }
    .contacts {
      margin-top: 20px;
    }
  }

  .button {
    text-align: center;
    height: 40px;
    width: 150px;
    border: solid 1px #dfdfdf;
    line-height: 40px;
    cursor: pointer;
    border-radius: 15px;
    margin-top: 40px;
  }
}
.cell-wrapper:hover {
  color: #27a5f9;
  background: #eef8ff;
  // transform: scaleX(1.045);
  .button {
    background-color: #0046c0;
    color: #fff;
  }
}
</style>
