<template>
  <div class="cell-wrapper">
    <img :src="img" class="image" />
    <div class="right">
      <div class="title h2 single-line">{{ info.parkName }}</div>
      <div class="address">
        <img src="@/static/images/location.png" class="location" />
        <div class="single-line h3 address-text">{{ info.parkAddress }}</div>
      </div>
      <div class="huaxiang">
        <div class="h3">园区画像：</div>
        <div class="tag" v-for="title in tags" :key="title">{{ title }}</div>
      </div>
    </div>
    <div class="button" @click="handleClick">查看详情</div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data() {
    return {}
  },
  computed: {
    img() {
      if (this.info.itemPicUrl) {
        return this.info.itemPicUrl
      }
      if (this.info.pictureUrl) {
        return this.info.pictureUrl
      }
      return ''
    },
    tags() {
      var parkPortrait = this.info?.parkPortrait ?? ''
      var tags1 = parkPortrait.split(',')
      var tags2 = parkPortrait.split(',')
      return tags1.length > tags2.length ? tags1 : tags2;
    },
  },
  created() { },
  mounted() {

  },
  methods: {
    handleClick() {
      this.$emit('handleClick', this.info)
    },
  }
}
</script>

<style scoped lang="less">
.cell-wrapper {
  height: 120px;
  display: flex;
  align-items: stretch;
  padding: 30px;
  border-bottom: #dfdfdf 1px solid;

  .image {
    width: 200px;
    height: 100%;
    margin-right: 20px;
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .title {
      height: 30px;
    }

    .content {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .address {
      display: flex;
      align-items: center;
      margin-top: 20px;

      .location {
        width: 15px;
        height: auto;
        margin-right: 10px;
      }
    }

    .huaxiang {
      display: flex;
      align-items: center;
      margin-top: 20px;

      .tag {
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        background-color: #a4d9fc;
        text-align: center;
        margin-right: 20px;
        border-radius: 2px;
      }
    }
  }

  .button {
    height: 40px;
    width: 150px;
    border: solid 1px #dfdfdf;
    line-height: 40px;
    cursor: pointer;
    border-radius: 15px;
    text-align: center;
    margin-top: 40px;
  }
}

.cell-wrapper:hover {
  color: #27a5f9;
  background: #eef8ff;

  // transform: scaleX(1.045);
  .button {
    background-color: #0046c0;
    color: #fff;
  }
}
</style>
