<template>
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <div class="flex-box">
        <vertical-menu
          class="left-menu-box"
          title="招商引资"
          :menuData="menuData"
          @handleMenuChange="handleMenuChange"
          :defaultCode="menu_id"
        ></vertical-menu>
        <!-- 入驻企业列表 -->
        <div class="right-list-box" v-if="menu_id=='ruzhuqiye'">
          <cell-ruzhuqiye
            v-for="item in dataList1"
            :key="item.id"
            @handleClick="qiyeDetailClick"
            :info="item"
          ></cell-ruzhuqiye>
          <div class="footer">
            <div class="left">共 {{total1}} 条</div>

            <el-pagination
              background
              layout="prev, pager, next"
              :total="total1"
              :current-page.sync="queryParams1.pageNum"
              :page-size="queryParams1.pageSize"
              @current-change="getList1"
            ></el-pagination>
          </div>
        </div>
        <!-- 入驻申请 -->
        <div class="right-list-box" v-if="menu_id=='ruzhushenqing'">
          <div style="width: 80%;margin: 20px auto;">
            <div
              style="height: 44px;background-color: #E8EDF8;text-align: center;line-height: 44px;margin-bottom: 20px;"
              class="h2"
            >企业入驻申请</div>
            <el-form ref="form" :model="form" label-width="170px" label-suffix=":">
              <el-form-item label="企业名称">
                <el-input v-model="form.enterpriseName" placeholder="请输入企业名称"></el-input>
              </el-form-item>
              <el-form-item label="您公司/项目核心业务">
                <el-input v-model="form.coreBusiness" placeholder="请输入项目核心业务名称"></el-input>
              </el-form-item>
              <el-form-item label="您所需要的载体类型">
                <el-radio-group v-model="form.carrierType">
                  <el-radio label="1">土方</el-radio>
                  <el-radio label="2">厂房</el-radio>
                  <el-radio label="3">仓库</el-radio>
                  <el-radio label="4">写字楼</el-radio>
                  <el-radio label="5">其他</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="请输入您需要的空间面积">
                <el-row>
                  <el-col :span="12">
                    <el-input v-model="form.area" placeholder="请输入面积"></el-input>
                  </el-col>
                  <el-col :span="8" :offset="1">
                    <el-select v-model="form.areaUnit" placeholder="请选择">
                      <el-option label="平米" value="1"></el-option>
                      <el-option label="公顷" value="2"></el-option>
                      <el-option label="平方千米" value="3"></el-option>
                      <el-option label="亩" value="4"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="请选择您的意向区域">
                <!-- <el-select v-model="form.interestArea" placeholder="请选择意向区域" style="width:100%;">
                  <el-option label="区域A" value="1"></el-option>
                  <el-option label="区域B" value="2"></el-option>
                </el-select>-->
                <el-input v-model="form.interestArea" placeholder="请选择意向区域"></el-input>
              </el-form-item>
              <el-form-item label="该项目预计投资">
                <el-input v-model="form.estimatedInvestment" placeholder="请输入项目预计投资">
                  <template slot="append">万元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="入住需求">
                <el-input
                  type="textarea"
                  v-model="form.settlementDemand"
                  placeholder="请简要描述您的入驻需求"
                  rows="4"
                  resize="none"
                ></el-input>
              </el-form-item>
              <el-form-item label="姓名">
                <el-input v-model="form.contactName" placeholder="请输入您的姓名"></el-input>
              </el-form-item>
              <el-form-item label="您的联系方式">
                <el-input v-model="form.contactPhone" placeholder="请输入您的电话号码"></el-input>
              </el-form-item>
              <div style="display: flex;justify-content: center;">
                <el-button @click="goback" style="margin-right: 50px;width: 150px;">取消</el-button>
                <el-button type="primary" @click="onSubmit" style="width: 150px;">提交</el-button>
              </div>
            </el-form>
          </div>
        </div>

        <!-- 园区信息 -->
        <div class="right-list-box" v-if="menu_id=='yuanquxinxi'">
          <cell-yuanquxinxi
            v-for="item in dataList2"
            :key="item.id"
            @handleClick="yuanquDetail"
            :info="item"
          ></cell-yuanquxinxi>
          <div class="left">共 {{total2}} 条</div>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total2"
            :current-page.sync="queryParams2.pageNum"
            :page-size="queryParams2.pageSize"
            @current-change="getList2"
          ></el-pagination>
        </div>
        <!-- 厂房出租 -->
        <div class="right-list-box" v-if="menu_id=='changfangzushou'">
          <cell-changfangzushou
            v-for="item in dataList3"
            :key="item.id"
            @handleClick="changfangDetail"
            :info="item"
          ></cell-changfangzushou>
          <div class="footer">
            <div class="left">共 {{total3}} 条</div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total3"
              :current-page.sync="queryParams3.pageNum"
              :page-size="queryParams3.pageSize"
              @current-change="getList3"
            ></el-pagination>
          </div>
        </div>
        <!-- 楼宇租售 -->
        <div class="right-list-box" v-if="menu_id=='louyuzushou'">
          <cell-louyuzushou
            v-for="item in dataList4"
            :key="item.id"
            @handleClick="louyuDetailClick"
            :info="item"
          ></cell-louyuzushou>
          <div class="footer">
            <div class="left">共 {{total4}} 条</div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total4"
              :current-page.sync="queryParams4.pageNum"
              :page-size="queryParams4.pageSize"
              @current-change="getList4"
            ></el-pagination>
          </div>
        </div>
        <!-- 招引案例 -->
        <div class="right-list-box" v-if="menu_id=='zhaoyinanli'">
          <cell-ruzhuqiye
            v-for="item in dataList5"
            :key="item.id"
            @handleClick="anliDetail"
            :info="item"
          ></cell-ruzhuqiye>
          <div class="footer">
            <div class="left">共 {{total5}} 条</div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total5"
              :current-page.sync="queryParams5.pageNum"
              :page-size="queryParams5.pageSize"
              @current-change="getList5"
            ></el-pagination>
          </div>
        </div>

        <!-- 招引政策 -->
        <div class="right-list-box" v-if="menu_id=='zhaoyinzhengce'">
          <article-list @handleClick="articleClick" :data="dataList6"></article-list>
          <div class="footer">
            <div class="left">共 {{total6}} 条</div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total6"
              :current-page.sync="queryParams6.pageNum"
              :page-size="queryParams6.pageSize"
              @current-change="getList6"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import articleList from '@/components/articleList.vue'
import verticalMenu from '@/components/verticalMenu.vue'
import cellYuanquxinxi from './components/cell-yuanquxinxi.vue'
import cellChangfangzushou from './components/cell-changfangzushou.vue'
import cellRuzhuqiye from './components/cell-ruzhuqiye.vue'
import cellLouyuzushou from './components/cell-louyuzushou.vue'
import cellZhaoyinanli from './components/cell-zhaoyinanli.vue'
export default {
  name: 'Home',
  components: {
    articleList,
    verticalMenu,
    cellYuanquxinxi,
    cellChangfangzushou,
    cellRuzhuqiye,
    cellLouyuzushou,
    cellZhaoyinanli
  },
  data() {
    return {
      menuData: [{
        'name': '入驻企业',
        'code': 'ruzhuqiye'
      },
      {
        'name': '入驻申请',
        'code': 'ruzhushenqing'
      },
      {
        'name': '园区信息',
        'code': 'yuanquxinxi'
      },
      {
        'name': '厂房租售',
        'code': 'changfangzushou'
      },
      {
        'name': '楼宇租售',
        'code': 'louyuzushou'
      },
      {
        'name': '招引案例',
        'code': 'zhaoyinanli'
      },
      {
        'name': '招引政策',
        'code': 'zhaoyinzhengce'
      }
      ],
      menu_id: 'ruzhuqiye',
      form: {},
      formLoading: false,
      dataList1: [],
      queryParams1: {
        pageNum: 1,
        pageSize: 6,
      },
      total1: 0,
      dataList2: [],
      queryParams2: {
        pageNum: 1,
        pageSize: 6,
      },
      total2: 0,
      dataList3: [],
      queryParams3: {
        pageNum: 1,
        pageSize: 6,
      },
      total3: 0,
      dataList4: [],
      queryParams4: {
        pageNum: 1,
        pageSize: 6,
      },
      total4: 0,
      dataList5: [],
      queryParams5: {
        pageNum: 1,
        pageSize: 6,
      },
      total5: 0,
      dataList6: [],
      queryParams6: {
        pageNum: 1,
        pageSize: 6,
      },
      total6: 0,
    }
  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const menu_id = this.$route.query?.menu_id
    if (menu_id && menu_id != 'undefined') {
      this.menu_id = menu_id
    }
  },
  mounted() {
    this.getList1()
    this.getList2()
    this.getList3()
    this.getList4()
    this.getList5()
    this.getList6()
  },
  methods: {
    getList1() {
      const params = {
        ...this.queryParams1,
        'areaType': '1',
        'policyType': '1',
      }
      this.$api.solicitationCloud.businessEnterpriseList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList1 = data.rows;
          this.total1 = data.total;
        }
      })
    },
    getList2() {
      const params = {
        ...this.queryParams2,
        'areaType': '1',
        'policyType': '1',
      }
      this.$api.solicitationCloud.businessParkList(params).then((res) => {
        const data = res.data
        if (data) {
          this.dataList2 = data.rows;
          this.total2 = data.total;
        }
      })
    },
    getList3() {
      const params = {
        ...this.queryParams3,
        'areaType': '1',
        'policyType': '1',
      }
      this.$api.solicitationCloud.businessWorkshopList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList3 = data.rows;
          this.total3 = data.total;
        }
      })
    },
    getList4() {
      const params = {
        ...this.queryParams4,
        'areaType': '1',
        'policyType': '1',
      }
      this.$api.solicitationCloud.businessBuildingList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList4 = data.rows;
          this.total4 = data.total;
        }
      })
    },
    getList5() {
      const params = {
        ...this.queryParams5,
        'areaType': '1',
        'policyType': '1',
      }
      this.$api.solicitationCloud.businessExampleList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList5 = data.rows;
          this.total5 = data.total;
        }
      })
    },
    getList6() {
      const params = {
        ...this.queryParams6,
        'areaType': '1',
        'policyType': '1',
      }
      this.$api.solicitationCloud.businessPolicyList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList6 = data.rows;
          this.total6 = data.total;
        }
      })
    },

    handleMenuChange(item) {
      console.log('handleMenuChange = ', item)
      this.menu_id = item.code
    },
    goback() {
      this.$router.back()
    },
    onSubmit() {
      if (!this.form.enterpriseName) {
        this.$message.error('请输入企业名称');
        return
      }
      if (!this.form.coreBusiness) {
        this.$message.error('请输入核心业务');
        return
      }
      this.formLoading = true
      this.$api.solicitationCloud.businessApply(this.form).then((res) => {
        this.formLoading = false
        const code = res.data.code
        if (code == 200) {
          this.$message({
            message: '提交成功',
            type: 'success'
          });
        }
      }).catch(() => {
        this.formLoading = false
      })
    },
    anliDetail(item) {
      this.$router.push({ path: '/solicitationCloud/page-zsyz/anliDetail', query: { id: item.id, 'detailType': 'soli-anli' } })
    },
    articleClick(item) {
      this.$router.push({ path: '/solicitationCloud/page-zsyz/articledetail', query: { id: item.id, 'detailType': 'soli-zhaoyinzhengce' } })
    },
    yuanquDetail(item) {
      this.$router.push({ path: '/solicitationCloud/page-zsyz/detail-yuanquxinxi', query: { id: item.id } })
    },
    changfangDetail(item) {
      this.$router.push({ path: '/solicitationCloud/page-zsyz/detail-changfang', query: { id: item.id } })
    },
    louyuDetailClick(item) {
      this.$router.push({ path: '/solicitationCloud/page-zsyz/detail-louyuzushou', query: { id: item.id } })
    },
    qiyeDetailClick(item) {
      this.$router.push({ path: '/solicitationCloud/page-zsyz/detail-ruzhuqiye', query: { id: item.id } })
    },
  }
}
</script>
<style scoped lang="less">
.policy-article-wrapper {
  display: flex;
  flex-direction: row;

  .left {
  }

  .right {
  }
}
</style>
